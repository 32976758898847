import React, { useState } from 'react'
import styled, { css } from 'styled-components'

const Container = styled.div`
  position: relative;
`

const Dropdown = styled.div`
  position: absolute;
  left: 0px;
  background: #FFFFFF;
  border: 1px solid #EEEEEE;
  box-sizing: border-box;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.15);
  border-radius: 16px;
  z-index: 999 !important;
  overflow-x: hidden;
  height: auto !important;
  width: auto !important;
  transition: all 200ms ease-in-out;
  ${({ visible }) =>
    visible
      ? css`
          visibility: visible;
          opacity: 1;
          transform: translateY(0);
        `
      : css`
          visibility: hidden;
          opacity: 0;
          transform: translateY(-2px);
        `};
  ${({ leftAlign }) =>
    leftAlign
      ? css`
          left: -70%;
        `
      : css`
        `};
`

const HoverDropdown = ({ children, render, leftAlign = false }) => {
  const [visible, setVisible] = useState(false)

  function show() {
    setVisible(true)
  }

  function hide() {
    setVisible(false)
  }

  return (
    <Container
      onMouseEnter={show}
      onMouseLeave={hide}
      onFocus={show}
      onBlur={hide}

    >
      {children}
      <Dropdown leftAlign={leftAlign} visible={visible}>{render}
      </Dropdown>
    </Container>
  )
}

export default HoverDropdown
