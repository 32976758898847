import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import { Link } from 'gatsby'

import MenuIcon from '../icons/menu'
import CloseIcon from '../icons/close'
import Li from './li'
import MenuAccordion from './navMenuAccordion'
import Plus from '../icons/plus'
import DropdownNext from '../icons/dropdownNext'
import generateMagicLink from '../utils/handleLogin'
import { BurgerMenuIcon } from '../icons'

const NavMenuMobile = ({
  open,
  toggle,
  userSession,
  handleLogout,
  token,
  workspaces = [],
}) => {
  const [expandedId, setExpandedId] = useState(null)

  const handleAccordionChange = id => {
    setExpandedId(id === expandedId ? null : id)
  }

  useEffect(() => {
    if (!open) {
      setExpandedId(null)
      // unset background scroll when menu is OPEN
      document.body.style.overflow = ''
    } else {
      // prevent background scroll when menu is OPEN
      document.body.style.overflow = 'hidden'
    }
  }, [open])

  return (
    <>
      <Container onClick={toggle}>
        {open ? <CloseIcon width={24} height={24} /> : <BurgerMenuIcon />}
      </Container>
      <MobileMenu visible={open}>
        <Links>
          <Li>
            <StyledLink to="/">Product</StyledLink>
          </Li>
          <Li>
            <MenuAccordion
              title="Use Cases"
              handleChange={() => handleAccordionChange('use-cases')}
              id="use-cases"
              expanded={expandedId}
            >
              <SubLinks>
                <SubLink to="/use-cases/leadership">Leadership</SubLink>
                <SubLink to="/use-cases/engineering">Engineering</SubLink>
                <SubLink to="/use-cases/product">Product</SubLink>
                <SubLink to="/use-cases/design">Design</SubLink>
                <SubLink to="/use-cases/sales">Sales</SubLink>
                <SubLink to="/use-cases/hr">People Ops</SubLink>
                <SubLink to="/use-cases/internal-comms">Internal Comms</SubLink>
                <SubLink to="/use-cases/marketing">Marketing</SubLink>
              </SubLinks>
            </MenuAccordion>
          </Li>
          <Li>
            <MenuAccordion
              title="Resources"
              handleChange={() => handleAccordionChange('resources')}
              id="resources"
              expanded={expandedId}
            >
              <SubLinks>
                <SubLink to="/blog/">Blog</SubLink>
                <SubLink to="/operators/">Cult Operations</SubLink>
                <SubLink to="/changelog/">Changelog</SubLink>
                <A
                  href="https://getthepulse.zendesk.com/hc/en-us"
                  style={{ color: 'black', textDecoration: 'none' }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Help Center
                </A>
                <A
                  href="mailto:sales@pulse.so"
                  style={{ color: 'black', textDecoration: 'none' }}
                >
                  Contact Us
                </A>
              </SubLinks>
            </MenuAccordion>
          </Li>
          <Li>
            <StyledLink to="/pricing/">Pricing</StyledLink>
          </Li>
          {/* <Li>
            <StyledLink to="/customers/">Customers</StyledLink>
          </Li> */}
          <Li>
            {userSession ? (
              <MenuAccordion
                title="Connect To Pulse"
                handleChange={() => handleAccordionChange('connect-to-pulse')}
                id="connect-to-pulse"
                expanded={expandedId}
              >
                <SubLinks>
                  {workspaces.map(workspace => (
                    <SubLink
                      style={{
                        display: 'flex',
                      }}
                      key={workspace.id}
                      to="#"
                      onClick={async () => {
                        const mlUrl = await generateMagicLink(
                          token,
                          workspace.id
                        )
                        window.location.href = mlUrl
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          marginRight: '0.625rem',
                        }}
                      >
                        {workspace.picture && workspace.picture.url ? (
                          <img
                            src={workspace.picture.url}
                            width="24"
                            height="24"
                          />
                        ) : null}
                        {workspace.name}
                      </div>
                      <CenterColumn>
                        <DropdownNext />
                      </CenterColumn>
                    </SubLink>
                  ))}
                  <SubLink to="/signup" style={{ display: 'flex' }}>
                    Create workspace
                    <CenterColumn style={{ marginLeft: '0.625rem' }}>
                      <Plus />
                    </CenterColumn>
                  </SubLink>
                  <SubLink to="/login" onClick={event => handleLogout(event)}>
                    Sign in somewhere else
                  </SubLink>
                </SubLinks>
              </MenuAccordion>
            ) : (
              <StyledLink to="/signup">Get Pulse For Free</StyledLink>
            )}
          </Li>
        </Links>
        {/* <GetPulse visible={open} to="/signup">
          Connect To Pulse
        </GetPulse> */}
      </MobileMenu>
    </>
  )
}

const CenterColumn = styled.div`
  display: flex;
  flex-direction: column;
  height: auto !important;
  justify-content: center;
`

const Container = styled.button`
  display: block;
  background: none;
  border: none;
  cursor: pointer;
  height: 100%;
  padding: 0;
  margin: 0;
  outline: none;

  svg {
    width: auto;
    height: 100%;
  }

  @media (min-width: 72.5rem) {
    display: none;
  }
`

const MobileMenu = styled.div`
  position: fixed;
  overflow: hidden;
  left: 0;
  top: 0;
  width: 100vw;
  height: calc(100vh - 4rem);
  box-sizing: border-box;
  left: 0;
  transition: 200ms;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-color: ${props => props.theme.core.background.primary};
  top: ${props => props.theme.spacing(8)};

  ${({ visible }) =>
    visible
      ? css`
          visibility: visible;
          transform: translateY(0%);
          z-index: 99;
        `
      : css`
          visibility: hidden;
          transform: translateY(-100%);
          z-index: -1;
        `}
`

const Links = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
  margin-bottom: 5rem;
  padding-left: ${props => props.theme.spacing(2)};
  @media (min-width: 72.5rem) {
    padding-left: ${props => props.theme.spacing(10)};
  }
`

const StyledLink = styled(Link)`
  width: 100%;
  text-decoration: none;
  display: block;
  font-weight: bold;
  font-size: 1.375rem;
  line-height: 1rem;
  margin-bottom: 2rem;
  color: ${props => props.theme.core.content.primary};
`

const SubLink = styled(Link)`
  font-family: ${props => props.theme.typography.fontFamilyInter};
  font-style: normal;
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1.5rem;
  color: #545454;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin-bottom: 1.5rem;
  text-decoration: none;
`

const A = styled.a`
  font-family: ${props => props.theme.typography.fontFamilyInter};
  font-style: normal;
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1.5rem;
  color: #545454 !important;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin-bottom: 1.5rem;
  text-decoration: none;
`

const SubLinks = styled.ul`
  display: flex;
  flex-direction: column;
  list-style-type: none;
  margin: 0;
  padding: 0;
  margin-bottom: 1rem;
  padding-left: 0.25rem;
`

const GetPulse = styled(Link)`
  font-family: ${props => props.theme.typography.fontFamilyGTBold};
  font-style: normal;
  font-weight: bold;
  font-size: 1.125rem;
  line-height: 1.5rem;
  text-transform: uppercase;
  color: #ffffff;
  width: 100%;
  height: 4.5rem;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  position: absolute;
  bottom: 0;

  ${({ visible }) =>
    visible
      ? css`
          visibility: visible;
        `
      : css`
          visibility: hidden;
        `}
`

export default NavMenuMobile
