import React from 'react'

export const CheckMarkIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM16.7071 10.7071C17.0976 10.3166 17.0976 9.68342 16.7071 9.29289C16.3166 8.90237 15.6834 8.90237 15.2929 9.29289L11 13.5858L8.70711 11.2929C8.31658 10.9024 7.68342 10.9024 7.29289 11.2929C6.90237 11.6834 6.90237 12.3166 7.29289 12.7071L10.2929 15.7071C10.6834 16.0976 11.3166 16.0976 11.7071 15.7071L16.7071 10.7071Z" fill="black" />
  </svg>
)

export const BurgerMenuIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3 6H21" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M3 12H21" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M3 18H21" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
)

export const PlayVideoIcon = (props) => (
  <svg width="68" height="70" viewBox="0 0 68 70" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g filter="url(#filter0_dd_357_24636)">
      <path d="M2 34.1172C2 16.4441 16.3269 2.11719 34 2.11719C51.6731 2.11719 66 16.4441 66 34.1172C66 51.7903 51.6731 66.1172 34 66.1172C16.3269 66.1172 2 51.7903 2 34.1172Z" fill="black" />
      <g clip-path="url(#clip0_357_24636)">
        <path d="M26 42.0667V26.1676C26 24.6453 27.602 23.6552 28.9636 24.336L44.8627 32.2856C46.3721 33.0402 46.372 35.1941 44.8627 35.9488L28.9636 43.8984C27.602 44.5791 26 43.589 26 42.0667Z" fill="white" />
      </g>
    </g>
    <defs>
      <filter id="filter0_dd_357_24636" x="0" y="0.117188" width="68" height="69" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_357_24636" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset />
        <feGaussianBlur stdDeviation="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
        <feBlend mode="normal" in2="effect1_dropShadow_357_24636" result="effect2_dropShadow_357_24636" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_357_24636" result="shape" />
      </filter>
      <clipPath id="clip0_357_24636">
        <rect width="20" height="20" fill="white" transform="translate(26 24.1172)" />
      </clipPath>
    </defs>
  </svg>

)
