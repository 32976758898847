import * as React from 'react'

function SvgComponent(props) {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12 5.333v2.534l-4 3.067-4-3.067V5.333L8 8.4l4-3.067z"
        fill="#000"
      />
    </svg>
  )
}

export default SvgComponent
