import React, { useState, useRef, useEffect } from 'react'
import styled from 'styled-components'
import ChevronIcon from '../icons/chevron-nav-accordion'

const AccordionSection = styled.div`
  display: flex-direction;
  flex-direction: column;
`

const AccordionButton = styled.button`
  background: none;
  padding: 0;
  margin: 0;
  text-align: left;
  width: 100%;
  border: none;
  display: flex;
  align-items: center;
  outline: none;
  margin-bottom: 2rem;
  cursor: pointer;
`

const AccordionTitle = styled.p`
  text-decoration: none;
  display: block;
  font-weight: bold;
  font-size: 1.375rem;
  line-height: 1rem;
  color: ${props => props.theme.core.content.primary};
`

const AccordionContent = styled.div`
  overflow: hidden;
  transition: all 200ms ease;
`

const AccordionIcon = styled.div`
  margin-left: 0.5rem;
  transition: transform 200ms ease;
  ${props => props.active && 'transform: rotate(180deg);'}
`

const NavMenuAccordion = ({ title, children, expanded, id, handleChange }) => {
  const [height, setHeight] = useState('0rem')
  const contentRef = useRef(null)
  const active = expanded === id

  useEffect(() => {
    setHeight(active ? `${contentRef.current.scrollHeight}px` : '0rem')
  }, [active])

  return (
    <AccordionSection>
      <AccordionButton onClick={handleChange}>
        <AccordionTitle>{title}</AccordionTitle>
        <AccordionIcon active={active}>
          <ChevronIcon />
        </AccordionIcon>
      </AccordionButton>
      <AccordionContent ref={contentRef} style={{ maxHeight: height }}>
        {children}
      </AccordionContent>
    </AccordionSection>
  )
}

export default NavMenuAccordion
