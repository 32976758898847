import { useState, useEffect } from 'react'
import { getWidth, getHeight } from '../getClientSize'
import useHasMounted from './useHasMounted'

const useClientSize = () => {
  const hasMounted = useHasMounted()
  const [width, setWidth] = useState(hasMounted && getWidth())
  const [height, setHeight] = useState(hasMounted && getHeight())

  useEffect(() => {
    if (!hasMounted) return

    function onChangeSize() {
      setWidth(getWidth())
      setHeight(getHeight())
    }

    onChangeSize()

    new ResizeObserver(onChangeSize).observe(document.body)
  }, [hasMounted])

  return { width, height }
}

export default useClientSize
