import axios from 'axios'
import config from './config'

async function generateMagicLink(authToken, tenantId) {
  return await axios
    .post(
      `${config.backendUri}/auth/session/magic-links`,
      {
        tenantId,
      },
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }
    )
    .then(mlResult => {
      console.log(mlResult.data.magicLink)
      return mlResult.data.magicLink
    })
    .catch(mlError => {
      console.error(mlError)
      throw new Error({
        response: { data: { message: 'Cannot generate Magic Link' } },
      })
    })
}

export default generateMagicLink
