import React, { useEffect, useRef } from 'react'
import styled, { keyframes } from 'styled-components'

export const enterAnimation = keyframes`
  0% {
    transform: translateY(0.625rem);
    opacity: 0;
  };

  100% {
    transform: translateY(0);
    opacity: 1;
  }
`

export const enterAnimationOuter = keyframes`
  0% {
    opacity: 0;
  };

  100% {
    opacity: 1;
  }
`

export const ModalOuter = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.2);
  background-filter: blur(16px);
  z-index: 9999999;
  align-items: center;
  justify-content: center;
  animation: ${enterAnimationOuter} 100ms cubic-bezier(0.39, 0.575, 0.565, 1)
    both;
`

export const ModalInner = styled.div`
  margin: auto;
  padding: 1rem;
  box-sizing: border-box;
  background: white;
  transition: all 100ms;
  z-index: 9999999;
  position: relative;
  width: ${props => props.width && props.width};
  height: ${props => props.height && props.height};
  box-shadow: 0 0 1.25rem 0 rgba(0, 0, 0, 0.1);
  animation: ${enterAnimation} 100ms cubic-bezier(0.39, 0.575, 0.565, 1) both;

  background: #FFFFFF;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.15);
  border-radius: 16px;
`

const Modal = ({
  children,
  visible,
  width,
  height,
  padding,
  closeModal,
  innerModalStyle,
  outerModalStyle,
}) => {
  const innerModal = useRef()
  const outerModal = useRef()

  useEffect(() => {
    function handleOutsideClick(e) {
      if (
        visible &&
        outerModal.current &&
        outerModal.current.contains(e.target) &&
        !innerModal.current.contains(e.target)
      ) {
        closeModal()
      }
    }
    document.addEventListener('click', handleOutsideClick, false)

    return () =>
      document.removeEventListener('click', handleOutsideClick, false)
  }, [closeModal, visible])

  return (
    <>
      {visible && (
        <ModalOuter ref={outerModal} style={outerModalStyle}>
          <ModalInner
            ref={innerModal}
            width={width}
            height={height}
            padding={padding}
            style={innerModalStyle}
          >
            {children}
          </ModalInner>
        </ModalOuter>
      )}
    </>
  )
}

export default Modal
