import React from 'react'
import styled from 'styled-components'

const Input = styled.input`
  background: #FFFFFF;
  /* Shadows/Default */

  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15), 0px 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  transition: all 200ms ease-in-out;
  ::placeholder,
  ::-webkit-input-placeholder {
    color: ${props => props.darkMode ? '#777777' : '#CFCFCF'};
  }
  :-ms-input-placeholder {
     color: ${props => props.darkMode ? '#777777' : '#CFCFCF'};
  }
  font-size: 16px;
  line-height: 20px;
  padding: 10px 16px;
  color: ${props => props.darkMode ? props.theme.core.content.inversePrimary : props.theme.core.content.primary};
  background: ${props => props.darkMode ? '#444444' : '#FFFFFF'};
  :focus {
    outline: none;
  }
  font-family: Suisse Intl;
  box-sizing: border-box;
  // disabled placeholder
  :disabled {
    border: ${props => props.darkMode ? '1px solid #333333' : '1px solid #CFCFCF'};
    background: ${props => props.darkMode ? '#222222' : '#F7F7F7'};
    color: ${props => props.darkMode ? '#444444' : '#CFCFCF'};
    cursor: not-allowed;
  }
`

// const Input = () => {
//   return (
//     <StyledInput
//     />
//   )
// }

export default Input
