import React from 'react'
import { noop } from 'lodash'
import Modal from './modal'
import Button from './button'
import Input from './starter-1.1.0/input'
import styled from 'styled-components'
import config from '../utils/config'

const BookCallModal = ({
  open = false,
  onClose = noop
}) => {
  const emailRef = React.useRef()
  const titleRef = React.useRef()
  const messageRef = React.useRef()

  const [email, setEmail] = React.useState('')
  const [title, setTitle] = React.useState('')
  const [message, setMessage] = React.useState('')

  const [isSubmitSuccess, setIsSubmitSuccess] = React.useState(false)
  const [submitError, setSubmitError] = React.useState(null)
  const [isSubmitting, setIsSubmitting] = React.useState(false)

  const handleClose = () => {
    setIsSubmitSuccess(false)
    setSubmitError(null)
    setIsSubmitting(false)
    onClose()
  }


  const handleSubmit = (event) => {
    event.preventDefault()
    setSubmitError(null)
    const formData = new FormData(event.target)
    const requestDemoApi = `${config.backendUri}/services/website/demo/request`
    setIsSubmitting(true)

    fetch(
      requestDemoApi,
      {
        method: 'POST',
        body: JSON.stringify({
          email: formData.get('email'),
          title: formData.get('title'),
          message: messageRef.current.innerText,
        }),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      },
    )
      .then((res) => {
        if (res.status === 200) {
          setIsSubmitSuccess(true)
          setTimeout(() => {
            handleClose()
          }, [2000])
        } else {
          setSubmitError('An error occured while submitting your request')
        }
        setIsSubmitting(false)
      })
      .catch(() => {
        setSubmitError('An error occured while submitting your request')
        setIsSubmitting(false)
      })
  }

  return (
    <Modal
      visible={open}
      closeModal={handleClose}
      width='428px'
    >
      <div className='font-display text-2xl leading-30'>
        Request a demo
      </div>
      <div className='whitespace-pre-wrap mt-16 text-gray-foreground text-base leading-24'>
        Together, we will look at how to<br />
        use Pulse within your organization.
      </div>
      <form
        method="post"
        action="https://www.mailblast.io/lists/5e950c49-d812-4520-a494-f682e1acfb3e/subscribers/create_external"
        // noValidate="novalidate"
        onSubmit={handleSubmit}
      >
        <div className='bg-gray-foreground-100 mt-16' style={{ borderRadius: '16px', border: '1px solid #DBDBDB' }}>
          <div className='px-12 py-8' style={{ borderBottom: '1px solid #DBDBDB' }}>
            <div className='font-display text-lg leading-24 text-gray-foreground-900'
              onClick={() => {
                emailRef.current.focus()
              }}
            // onChange={(event) => setEmail(event.target.value)}
            // value={email}
            >
              Email
            </div>
            <StyledInput
              placeholder='leonardo@pulse.so'
              autoFocus
              ref={emailRef}
              type='email'
              name='email'
              required
            />
          </div>
          <div className='px-12 py-8' style={{ borderBottom: '1px solid #DBDBDB' }}>
            <div className='font-display text-lg leading-24 text-gray-foreground-900'
              onClick={() => {
                titleRef.current.focus()
              }}
            // onChange={(event) => setTitle(event.target.value)}
            // value={title}
            >
              Title
            </div>
            <StyledInput
              placeholder='COO'
              ref={titleRef}
              name='title'
              required
            />
          </div>
          <div className='px-12 py-8'>
            <div className='font-display text-lg leading-24 text-gray-foreground-900'
              onClick={() => {
                messageRef.current.focus()
              }}
            // onChange={(event) => setMessage(event.target.value)}
            // value={message}
            >
              Message
            </div>
            <TextAreaContainer>
              <StyledTextArea
                contentEditable
                placeholder='What are you hoping to accomplish with Pulse?'
                ref={messageRef}
                name='message'
                aria-multiline
                multiline
                type='text'
              />
            </TextAreaContainer>
          </div>
        </div>
        {submitError && (
          <div className='my-16 font-display w-full text-center text-sm' style={{ color: '#EE0000' }}>
            {submitError}
          </div>
        )}
        {isSubmitSuccess && (
          <div className='my-16 font-display w-full text-center text-sm' style={{ color: '#47CD43' }}>
            Request sent! We'll reach out to you soon.
          </div>
        )}
        <Button secondary className='mt-10 w-full'
          // disabled={!email || !title} 
          type='submit' loading={isSubmitting}>
          Request a Demo
        </Button>
      </form>
      <div className='mt-16 text-center text-sm leading-17 font-display text-gray-foreground-400'>
        Or skip the line and <a className='underline text-gray-foreground-400 text-sm leading-17' href='https://cal.com/leonardofed/onboarding-call'
          target="_blank"
          rel="noopener noreferrer"
        >book a demo</a>.
      </div>
    </Modal>
  )
}

export default BookCallModal

const StyledInput = styled.input`
  background: transparent;
  ::placeholder,
  ::-webkit-input-placeholder {
    color: ${props => props.darkMode ? '#777777' : '#CFCFCF'};
  }
  :-ms-input-placeholder {
     color: ${props => props.darkMode ? '#777777' : '#CFCFCF'};
  }
  :focus {
    outline: none;
  }
  font-size: 16px;
  line-height: 17px;
  width: 100%;
`

const StyledTextArea = styled.div`
  background: transparent;
  ::placeholder,
  ::-webkit-input-placeholder {
    color: ${props => props.darkMode ? '#777777' : '#CFCFCF'};
  }
  :-ms-input-placeholder {
     color: ${props => props.darkMode ? '#777777' : '#CFCFCF'};
  }
  :focus {
    outline: none;
  }
  font-size: 16px;
  line-height: 17px;
  width: 100%;
  min-height: calc(17px + 32px);
  height: auto;
  resize: none;
  margin-top: 6px;
`

const TextAreaContainer = styled.div`
  [contenteditable][placeholder]:empty:before {
    content: attr(placeholder);
    position: absolute;
    color: #CFCFCF;
    background-color: transparent;
    ont-size: 16px;
    line-height: 17px;
  }
`