import React, { useContext, useState } from 'react'
import styled, { css, useTheme } from 'styled-components'
import { Link, navigate } from 'gatsby'

import Box from './box'
import NavLink from './navLink'
import Dropdown from './hoverDropdown'
import PulseLogo from '../icons/pulseLogoWhite'
import Chevron from '../icons/chevron'
import NavMenuMobile from './navMenuMobile'
import Li from './li'
import useClientSize from '../utils/hooks/useClientSize'
import Plus from '../icons/plus'
import { AuthContext, AuthProvider } from '../context/auth'
import asyncLocalStorage from '../utils/asyncLocalStorage'
import DropdownNext from '../icons/dropdownNext'
import generateMagicLink from '../utils/handleLogin'
import Button from './button'
import { isMobile } from 'react-device-detect'
import { BurgerMenuIcon } from '../icons'
import { useLocation } from '@reach/router'
import BookCallModal from './bookCallModal'

const NavContainer = styled.header`
  display: flex;
  /* position: sticky; */
  position: fixed;
  z-index: ${props => props.theme.zIndex.nav};
  top: 0px;
  max-width: 100vw;
  width: 100vw;
  box-sizing: border-box;
  height: ${props => props.theme.spacing(8)};
  /* background-color: ${props =>
    props.isPurpleTheme
      ? '#9601FF'
      : props.isMobileNavOpen || props.isLightTheme
        ? props.theme.colors.white
        : props.theme.colors.black}; */
  padding-left: ${props => props.theme.spacing(2)};
  padding-right: ${props => props.theme.spacing(2)};
  transition: 100ms;
  background: linear-gradient(180deg, #FFFFFF 1.42%, rgba(255, 255, 255, 0) 100%);
  backdrop-filter: blur(20px);
  padding-top: 12px;
  padding-bottom: 12px;
  @media (min-width: 1160px) {
    padding-left: ${props => props.theme.spacing(10)};
    padding-right: ${props => props.theme.spacing(10)};
  }
  @media ${props => props.theme.device.tabletLToLower} {
    padding: 20px 16px;
  }
`

const InnerContainer = styled.div`
  width: 100%;
  height: 100%;
  max-width: 1200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin: auto;
  height: 100%;
`

const NavLinks = styled.nav`
  display: flex;
  align-items: center;
  display: none;
  height: 100%;
  /* margin-left: 52px; */

  * {
    height: 100%;
  }

  @media (min-width: 1160px) {
    display: flex;

    a {
      margin-right: 24px;
    }
  }
`

const NavLogin = styled.nav`
  display: flex;
  align-items: center;
  height: 100%;
  padding-right: 0px;
`

const LogoContainer = styled.div``

const DropdownMenu = styled.div`
  display: flex;
  flex-direction: column;
  width: 270px;
  height: auto;
  a {
    color: #111111;
    text-decoration: none;
    padding: 8px 12px;
    display: block;
    width: 100%;
    display: flex;
    align-items: center;
  }
  li:not(last-child) {
    border-bottom: 1px solid #EEEEEE;
  }

  a:hover {
    background-color: ${props => props.theme.colors.gray[100]};
  }
`

const NavText = styled.button`
  background: none;
  border: none;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  text-transform: capitalize;
  color: #777777;
  /* color: ${props =>
    props.isLightTheme
      ? props.theme.core.content.primary
      : props.theme.core.content.inversePrimary}; */
  text-decoration: none;
  transition: all 100ms ease-in-out;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right: 24px;
  outline: none;
  font-family: Suisse Intl;
  :hover,
  :focus {
    opacity: 0.8;
  }
`

const GetPulseNav = styled.div`
  background: ${props =>
    props.isPurpleTheme
      ? 'rgb(255 255 255 / 30%)'
      : props.isLightTheme
        ? 'black'
        : '#535351'};
  height: 100%;
  font-size: 16px;
  line-height: 16px;
  padding: 24px 32px;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: bold;
  color: #000000;
  text-decoration: none;
  display: none;

  @media (min-width: 1160px) {
    display: flex;
  }
`

const ConnectToPulseLink = styled(Link)`
  background: ${props =>
    props.isPurpleTheme
      ? 'rgb(255 255 255 / 30%)'
      : props.isLightTheme
        ? 'black'
        : '#535351'};
  height: 100%;
  font-size: 16px;
  line-height: 16px;
  color: #ffffff;
  text-decoration: none;
  place-content: center;
  padding: 24px 32px;
  font-weight: 700;
  display: none;

  @media (min-width: 1160px) {
    display: grid;
  }
`

const DropdownAuthLink = styled(Link)`
  border-top: 1px solid rgba(0, 0, 0, 0.08);
  margin-right: 24px;
  font-style: normal;
  /* font-weight: bold; */
  font-size: 16px;
  line-height: 20px;
  font-family: Suisse Intl;
  text-decoration: none;
  transition: 100ms;
  padding: 8px 12px !important;
  display: flex;
  justify-content: space-between;
  height: auto !important;
  color: ${props =>
    props.isMobileNavOpen || props.isLightTheme
      ? '#111111'
      : props.theme.colors.white};
`

const DropdownWorkspaceLink = styled(Link)`
  margin-right: 24px;
  font-style: normal;
  /* font-weight: bold; */
  font-size: 16px;
  line-height: 20px;
  text-decoration: none;
  transition: 100ms;
  padding: 8px 12px !important;
  display: flex;
  justify-content: space-between;
  height: auto !important;
  color: ${props =>
    props.isMobileNavOpen || props.isLightTheme
      ? '#111111'
      : props.theme.colors.white};
`

const LoginLink = styled(Link)`
  margin-right: 24px;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  text-decoration: none;
  transition: 100ms;
  color: ${props =>
    props.isMobileNavOpen || props.isLightTheme
      ? props.theme.colors.black
      : props.theme.colors.white};
`

const StyledLink = styled(Link)`
  // color: ${props => props.theme.core.content.secondary} !important;
  color: #111111;
  font-family: Suisse Intl;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  flex-direction: column;
  text-align: left !important;
  :hover {
    opacity: 1;
  }
`

const StyledAnchor = styled.a`
  text-decoration: none;
  color: #111111;
  font-family: Suisse Intl;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  flex-direction: column;
  text-align: left !important;
  :hover {
    opacity: 1;
  }
`

const themes = {
  light: 'light',
  dark: 'dark',
  purple: 'purple',
}

const NavWrapper = props => (
  <AuthProvider isNonAuthPage>
    <Nav {...props} />
  </AuthProvider>
)

const Nav = ({ theme = themes.dark, style = {} }) => {
  const styledTheme = useTheme()
  const { width } = useClientSize()
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false)
  const { userSession, workspaces, token } = useContext(AuthContext)
  const isLightTheme = theme === themes.light
  const isPurpleTheme = theme === themes.purple

  const { pathname } = useLocation()
  React.useEffect(() => {
    if (width >= 1160) {
      setIsMobileNavOpen(false)
    }
  }, [width])

  const toggleMobileNav = () => setIsMobileNavOpen(open => !open)
  const [isBookCallModalOpen, setIsBookCallModalOpen] = React.useState(false)

  return (
    <>
      <NavContainer style={style} className='!hidden tabletLToLower:!flex'>
        <div className='w-full flex justify-between'>
          <div
            className='grid place-content-center'
            onClick={() => {
              navigate('/')
            }}
          >
            <PulseLogo
              width="100px"
              height="25px"
              fill={
                styledTheme.colors.black
                // isMobileNavOpen || isLightTheme
                // ? styledTheme.colors.black
                // : styledTheme.colors.white
              }
            />
          </div>
          <div className='font-display text-base leading-20 text-gray-foreground flex'>
            <NavLink to='/login'
              className='grid place-content-center'
            >
              Login
            </NavLink>
            <div className='grid place-content-center ml-16'>
              {/* <BurgerMenuIcon /> */}
              <NavMenuMobile
                open={isMobileNavOpen}
                toggle={toggleMobileNav}
                userSession={userSession}
                token={token}
                handleLogout={event => {
                  event.preventDefault()
                  asyncLocalStorage.removeItem('session').then(() => {
                    navigate('/login')
                  })
                }}
                workspaces={workspaces}
              />
            </div>
          </div>
        </div>
      </NavContainer>
      <NavContainer style={style} className='!flex tabletLToLower:!hidden'>
        <BookCallModal
          open={isBookCallModalOpen}
          onClose={() => {
            setIsBookCallModalOpen(false)
          }}
        />
        <InnerContainer>
          <Box
            style={{
              display: 'flex',
              alignItems: 'center',
              height: '100%',
            }}
          >
            <NavLinks>
              <Li>
                <NavLink
                  to="/"
                // style={
                //   {
                //     // color:
                //   }
                // }
                >
                  Product
                </NavLink>
              </Li>
              <Dropdown
                render={
                  <DropdownMenu>
                    <Li>
                      <StyledLink to="/use-cases/leadership">
                        <div className='text-left w-full'>
                          <div className='text-left w-full font-display text-gray-foreground-900'>
                            Leadership
                          </div>
                          <div className='text-sm leading-17 text-gray-foreground text-left'>
                            Keep people inspired and aligned
                          </div>
                        </div>
                      </StyledLink>
                    </Li>
                    <Li>
                      <StyledLink to="/use-cases/engineering">
                        <div className='text-left w-full'>
                          <div className='text-left w-full font-display text-gray-foreground-900'>
                            Engineering
                          </div>
                          <div className='text-sm leading-17 text-gray-foreground text-left'>
                            Go async and ship more
                          </div>
                        </div>
                      </StyledLink>
                    </Li>
                    <Li>
                      <StyledLink to="/use-cases/product">
                        <div className='text-left w-full'>
                          <div className='text-left w-full font-display text-gray-foreground-900'>
                            Product
                          </div>
                          <div className='text-sm leading-17 text-gray-foreground text-left'>
                            Align people on product changes
                          </div>
                        </div>
                      </StyledLink>
                    </Li>
                    <Li>
                      <StyledLink to="/use-cases/design">
                        <div className='text-left w-full'>
                          <div className='text-left w-full font-display text-gray-foreground-900'>
                            Design
                          </div>
                          <div className='text-sm leading-17 text-gray-foreground text-left'>
                            Design with the garage door up
                          </div>
                        </div>
                      </StyledLink>
                    </Li>
                    <Li>
                      <StyledLink to="/use-cases/sales">
                        <div className='text-left w-full'>
                          <div className='text-left w-full font-display text-gray-foreground-900'>
                            Sales
                          </div>
                          <div className='text-sm leading-17 text-gray-foreground text-left'>
                            Accelerate training and onboarding
                          </div>
                        </div>
                      </StyledLink>
                    </Li>
                    <Li>
                      <StyledLink to="/use-cases/hr">
                        <div className='text-left w-full'>
                          <div className='text-left w-full font-display text-gray-foreground-900'>
                            People Ops
                          </div>
                          <div className='text-sm leading-17 text-gray-foreground text-left'>
                            Broadcast operational updates
                          </div>
                        </div>
                      </StyledLink>
                    </Li>
                    <Li>
                      <StyledLink to="/use-cases/internal-comms">
                        <div className='text-left w-full'>
                          <div className='text-left w-full font-display text-gray-foreground-900'>
                            Internal Comms
                          </div>
                          <div className='text-sm leading-17 text-gray-foreground text-left'>
                            The HQ for vital company news
                          </div>
                        </div>
                      </StyledLink>
                    </Li>
                    <Li>
                      <StyledLink to="/use-cases/marketing">
                        <div className='text-left w-full'>
                          <div className='text-left w-full font-display text-gray-foreground-900'>
                            Marketing
                          </div>
                          <div className='text-sm leading-17 text-gray-foreground text-left'>
                            Make customer insights discoverable
                          </div>
                        </div>
                      </StyledLink>
                    </Li>
                  </DropdownMenu>
                }
              >
                <NavText isLightTheme={isLightTheme}
                  style={pathname.startsWith('/use-cases/') ? { color: '#000000' } : {}}
                >
                  Use cases
                  <Box marginRight={styledTheme.spacing(0.5)} />
                  <Chevron
                    fill={
                      isLightTheme
                        ? styledTheme.core.content.primary
                        : styledTheme.core.content.inversePrimary
                    }
                  />
                </NavText>
              </Dropdown>
              {/* <Li>
                <A
                  href="https://getthepulse.zendesk.com/hc/en-us"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Guides
                </A>
              </Li> */}
              <Dropdown
                render={
                  <DropdownMenu>

                    <Li>
                      <StyledLink to="/blog">
                        <div className='text-left w-full'>
                          <div className='text-left w-full font-display text-gray-foreground-900'>
                            Blog
                          </div>
                          <div className='text-sm leading-17 text-gray-foreground text-left'>
                            Tips on Ops and Internal Comms
                          </div>
                        </div>
                      </StyledLink>
                    </Li>
                    <Li>
                      <StyledLink to="/operators">
                        <div className='text-left w-full'>
                          <div className='text-left w-full font-display text-gray-foreground-900'>
                            Cult Operations
                          </div>
                          <div className='text-sm leading-17 text-gray-foreground text-left'>
                            Ops content for decentralized teams
                          </div>
                        </div>
                      </StyledLink>
                    </Li>
                    <Li>
                      <StyledLink to="/changelog">
                        <div className='text-left w-full'>
                          <div className='text-left w-full font-display text-gray-foreground-900'>
                            Changelog
                          </div>
                          <div className='text-sm leading-17 text-gray-foreground text-left'>
                            Streams of changes to our product
                          </div>
                        </div>
                      </StyledLink>
                    </Li>
                    <Li>
                      <StyledAnchor href="https://support.pulseasync.com" target='_blank' rel='noopener noreferrer'>
                        <div className='text-left w-full'>
                          <div className='text-left w-full font-display text-gray-foreground-900'>
                            Help Center
                          </div>
                          <div className='text-sm leading-17 text-gray-foreground text-left'>
                            Guides to learn about the product
                          </div>
                        </div>
                      </StyledAnchor>
                    </Li>
                    <Li>
                      <StyledAnchor href="mailto:support@pulse.so" target='_blank' rel='noopener noreferrer'>
                        <div className='text-left w-full'>
                          <div className='text-left w-full font-display text-gray-foreground-900'>
                            Contact us
                          </div>
                          <div className='text-sm leading-17 text-gray-foreground text-left'>
                            Get in touch with our team
                          </div>
                        </div>
                      </StyledAnchor>
                    </Li>
                  </DropdownMenu>
                }
              >
                <NavText isLightTheme={isLightTheme}
                  style={['/blog', '/operators', '/changelog',].includes(pathname) ? { color: '#000000' } : {}}

                >
                  Resources
                  <Box marginRight={styledTheme.spacing(0.5)} />
                  <Chevron
                    fill={
                      isLightTheme
                        ? styledTheme.core.content.primary
                        : styledTheme.core.content.inversePrimary
                    }
                  />
                </NavText>
              </Dropdown>
              <Li>
                <NavLink
                  to="/pricing/"
                // style={{
                //   color: isLightTheme
                //     ? styledTheme.core.content.primary
                //     : styledTheme.core.content.inversePrimary,
                // }}
                >
                  Pricing
                </NavLink>
              </Li>
            </NavLinks>
          </Box>
          <Box
            style={{
              position: 'absolute',
              left: '50%',
              top: '50%',
              transform: 'translate(-50%, -50%)',
              cursor: 'pointer'
            }}
            onClick={() => {
              navigate('/')
            }}
          >
            <PulseLogo
              width="100px"
              height="25px"
              fill={
                styledTheme.colors.black
                // isMobileNavOpen || isLightTheme
                // ? styledTheme.colors.black
                // : styledTheme.colors.white
              }
            />
          </Box>
          <NavLinks>
            <Li className='grid place-content-center'>
              <a
                // href='#'
                // target="_blank"
                // rel="noopener noreferrer"
                className='text-gray-foreground no-underline font-display text-base leading-20 cursor-pointer'
                onClick={() => {
                  setIsBookCallModalOpen(true)
                }}
              // style={
              //   {
              //     // color:
              //   }
              // }
              >
                Get a demo
              </a>
            </Li>
            <Li>
              <NavLink
                to="/login/"
              // style={
              //   {
              //     // color:
              //   }
              // }
              >
                Login
              </NavLink>
            </Li>
            {userSession ? (
              <Dropdown
                leftAlign
                render={
                  <DropdownMenu
                  >
                    {workspaces.map(workspace => (
                      <DropdownWorkspaceLink
                        key={workspace.id}
                        to="#"
                        isMobileNavOpen={isMobileNavOpen}
                        isLightTheme={isLightTheme}
                        onClick={async () => {
                          const mlUrl = await generateMagicLink(
                            token,
                            workspace.id
                          )
                          window.location.href = mlUrl
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          {workspace.picture && workspace.picture.url ? (
                            <img
                              src={workspace.picture.url}
                              width="24"
                              height="24"
                              style={{ marginRight: '8px' }}
                            />
                          ) : null}
                          {workspace.name}
                        </div>
                        <DropdownNext />
                      </DropdownWorkspaceLink>
                    ))}
                    <DropdownAuthLink
                      to="/signup"
                      isMobileNavOpen={isMobileNavOpen}
                      isLightTheme={isLightTheme}
                    >
                      Create workspace <Plus />
                    </DropdownAuthLink>
                    <DropdownAuthLink
                      to="/login"
                      isMobileNavOpen={isMobileNavOpen}
                      isLightTheme={isLightTheme}
                      onClick={event => {
                        event.preventDefault()
                        asyncLocalStorage.removeItem('session').then(() => {
                          navigate('/login')
                        })
                      }}
                    >
                      Sign in somewhere else
                    </DropdownAuthLink>
                    {/* </Li> */}
                    {/* <Li>
                        <StyledLink to="/blog">
                          <div className='text-left w-full'>
                            <div className='text-left w-full font-display text-gray-foreground-900'>
                              Blog
                            </div>
                            <div className='text-sm leading-17 text-gray-foreground text-left'>
                              Tips on Ops and Internal Comms
                            </div>
                          </div>
                        </StyledLink>
                      </Li>
                      <Li>
                        <StyledLink to="/operators">
                          <div className='text-left w-full'>
                            <div className='text-left w-full font-display text-gray-foreground-900'>
                              Cult Operations
                            </div>
                            <div className='text-sm leading-17 text-gray-foreground text-left'>
                              Ops content for decentralized teams
                            </div>
                          </div>
                        </StyledLink>
                      </Li>
                      <Li>
                        <StyledLink to="/changelog">
                          <div className='text-left w-full'>
                            <div className='text-left w-full font-display text-gray-foreground-900'>
                              Changelog
                            </div>
                            <div className='text-sm leading-17 text-gray-foreground text-left'>
                              Streams of changes to our product
                            </div>
                          </div>
                        </StyledLink>
                      </Li>
                      <Li>
                        <StyledLink to="/use-cases/marketing">
                          <div className='text-left w-full'>
                            <div className='text-left w-full font-display text-gray-foreground-900'>
                              *Help Center
                            </div>
                            <div className='text-sm leading-17 text-gray-foreground text-left'>
                              Guides to learn about the product
                            </div>
                          </div>
                        </StyledLink>
                      </Li>
                      <Li>
                        <StyledLink to="/use-cases/marketing">
                          <div className='text-left w-full'>
                            <div className='text-left w-full font-display text-gray-foreground-900'>
                              *Contact us
                            </div>
                            <div className='text-sm leading-17 text-gray-foreground text-left'>
                              Accelerate training and onboarding
                            </div>
                          </div>
                        </StyledLink>
                      </Li> */}
                  </DropdownMenu>
                }
              >
                <NavText isLightTheme={isLightTheme}
                  style={{ marginRight: 0 }}
                >
                  Connect to pulse
                  <Box marginRight={styledTheme.spacing(0.5)} />
                  <Chevron
                    fill={
                      isLightTheme
                        ? styledTheme.core.content.primary
                        : styledTheme.core.content.inversePrimary
                    }
                  />
                </NavText>
              </Dropdown>
            ) : (
              <Li
                className='grid place-content-center'
              >
                <Button
                  to='/signup'
                  style={{
                    padding: '10px 18px',
                    height: '40px'
                  }}
                >
                  Sign up
                </Button>
              </Li>
            )}
          </NavLinks>
        </InnerContainer>
      </NavContainer>
    </>
  )
}

export default NavWrapper
