import * as React from 'react'

function SvgComponent(props) {
  return (
    <svg fill='none' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M18 6L6 18'
        stroke='#757575'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.5'
      />
      <path
        d='M6 6L18 18'
        stroke='#757575'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.5'
      />
    </svg>
  )
}

export default SvgComponent
